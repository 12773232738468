var env = process.env.GATSBY_ENVIRONMENT || 'development';
var MARKETING_TRACKING_SESSION_STORAGE_KEY = 'marketing_tracking';
var MARKETING_TRACKING_PARAM_LIST = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'matchtype', 'keyword', 'device', 'adposition', 'network', 'creative', 'aceid', 'campaignid', 'adgroupid', 'gclid'];
var EXTERNAL_PLATFORM_LINK_REGEX = /^(?:\/property-report\/my-properties|\/property-report\/relationship|\/book-appointment|\/home-loans\/basics|\/credit-score|\/stamp-duty-calculator)(?:\/.*)?$/;
var isURLOriginLendi = function isURLOriginLendi(url) {
  var lendiOrigins = ['lendi-dev.net', 'lendi-stg.net', 'lendi-preprod.net', 'lendi.com.au'];
  return lendiOrigins.some(function (origin) {
    return url.hostname.endsWith(origin);
  });
};
var embedMarketingTrackingIntoURL = function embedMarketingTrackingIntoURL(link) {
  var withOrigin = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (!link) return '';
  var baseURL = {
    development: 'https://www.lendi-dev.net',
    staging: 'https://www.lendi-stg.net',
    preproduction: 'https://www.lendi-preprod.net',
    production: 'https://www.lendi.com.au'
  }[env];
  try {
    var targetURL = new URL(link, baseURL);
    if (!isURLOriginLendi(targetURL) || !EXTERNAL_PLATFORM_LINK_REGEX.test(targetURL.pathname)) {
      // Do not embed UTM params for external links with different origin or internal CMS links
      return link;
    }
    var trackingParams = JSON.parse(sessionStorage.getItem(MARKETING_TRACKING_SESSION_STORAGE_KEY) || '{}');
    Object.keys(trackingParams).forEach(function (key) {
      targetURL.searchParams.set(key, trackingParams[key]);
    });
    return (withOrigin ? baseURL : '') + targetURL.pathname + targetURL.search;
  } catch (e) {
    return link;
  }
};
var withJamLink = function withJamLink(url) {
  if (!url) return '';
  if (typeof window !== 'undefined') {
    return url + window.location.search;
  }
  return url;
};
var addQueriesToLink = function addQueriesToLink(event) {
  event.currentTarget.setAttribute('href', event.currentTarget.getAttribute('href') + window.location.search);
  return true;
};

export { MARKETING_TRACKING_PARAM_LIST, MARKETING_TRACKING_SESSION_STORAGE_KEY, addQueriesToLink, embedMarketingTrackingIntoURL, withJamLink };
