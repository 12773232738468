import type { Brand, Identity } from '@lendi/lala-react';
import type { LDClient } from 'launchdarkly-js-client-sdk';

import { getLDUser } from './getLDUser';

export const identifyLDUser = async (
  ldClient: LDClient,
  brand: Brand,
  identity?: Identity,
  maxRetries = 3 // set up a max retry number
) => {
  const ldUser = await getLDUser(brand, identity);
  const tryToIdentify = async (timeout: number) =>
    await Promise.race([
      ldClient.identify(ldUser),
      new Promise((_, reject) =>
        setTimeout(reject, timeout, new Error(`${timeout}ms timeout reached waiting for launchdarkly identify!`))
      ),
    ]);
  for (let i = 0; i <= maxRetries; i++) {
    try {
      await tryToIdentify(1000 * (i + 1)); // increase timeout by 1s for every retry
      break;
    } catch (e) {
      if (i !== maxRetries) {
        // give a 0.1s break before re-attempting ld identify
        await new Promise((r) => setTimeout(r, 100));
      }
    }
  }
};
