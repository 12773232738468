import * as React from 'react';

import { identify as trackingIdentify} from '@lendi-jam/tracking';
import { CustomerType } from '@lendi/analytics-web';
import { Type, useSession } from '@lendi/lala-react';
import { useFlags, useLDClient } from 'gatsby-plugin-launchdarkly';

import { identifyLDUser } from './identifyLDUser';

const analyticsUserTypeMapping = {
  [Type.Team]: CustomerType.TEAM,
  [Type.Customer]: CustomerType.CUSTOMER,
};
interface AnalyticsIdentifierProps {
  userIdentified: boolean;
  userId: string;
  userType: CustomerType;
}

function AnalyticsIdentifier({ userIdentified, userId, userType }: AnalyticsIdentifierProps) {
  const allFlags = useFlags();

  React.useEffect(() => {
    if (!userIdentified) return;

    trackingIdentify(userId, allFlags, {
      user_type: userType,
    }); // analytics identify call
  }, [userId, userType, allFlags, userIdentified]);
  return null;
}

export default function LaunchDarklyProvider({ children }: { children: React.ReactNode }) {
  const ldClient = useLDClient();
  const { brand, identity, type } = useSession();
  const [isIdentified, setIsIdentified] = React.useState(false);

  React.useEffect(() => {
    if (ldClient) {
      identifyLDUser(ldClient, brand, identity).finally(() => setIsIdentified(true));
    }
  }, [ldClient, identity, brand]);

  return (
    <>
      <AnalyticsIdentifier
        userIdentified={isIdentified}
        userId={identity?.id ?? ''}
        userType={analyticsUserTypeMapping[type]}
      />
      {children}
    </>
  );
}
