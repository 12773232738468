import type { Brand, Identity } from '@lendi/lala-react';
import Cookies from 'js-cookie';
import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { v4 } from 'uuid';

export const getLDUser = async (brand: Brand, identity?: Identity): Promise<LDSingleKindContext> => {
  let targetId = Cookies.get('targetId') || v4();
  if (!identity) {
    return {
      anonymous: true,
      targetId,
      isTeamView: false,
      brand,
      kind: 'user',
    };
  }
  const response = await fetch(
    `https://app.launchdarkly.com/api/v2/users/funnels/${process.env.LAUNCH_DARKLY_ENV_KEY}/${identity.id}`,
    {
      method: 'GET',
      headers: {
        Authorization: process.env.LAUNCH_DARKLY_API_KEY || '',
      },
    }
  );
  const result = await response.json();
  targetId = result.data.user?.custom.targetId;
  return {
    key: identity.id,
    targetId,
    isTeamView: false,
    brand,
    kind: 'user',
  };
};
