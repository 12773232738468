module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lendiau","accessToken":"MC5YbWw2b2hVQUFDUUE0b21r.cu-_ve-_vQPvv70x77-977-977-977-9YFXvv73vv73vv73vv70d77-9Se-_vXTvv73vv73vv73vv73vv73vv73vv70Ebu-_ve-_vQ","graphQuery":"\n   {\n     generic_template {\n       ...generic_templateFields\n       parent {\n         ...parentFields\n       }\n     }\n   }\n  ","lang":"en-au","toolbar":"new","promptForAccessToken":true,"apiEndpoint":"https://lendiau.cdn.prismic.io/api/v2","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15}},
    },{
      plugin: require('../../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com","https://fonts.googleapis.com/"],"web":[{"name":"Cabin","file":"https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap"},{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lendi","short_name":"Lendi","start_url":"/","background_color":"#ffffff","theme_color":"#00c0a5","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"72cdf7edd3ca6e222efcae0835c04113"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"5e5486f5f434d50824ef46b0"},
    },{
      plugin: require('../../../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
