import * as React from 'react';

import { MARKETING_TRACKING_PARAM_LIST, MARKETING_TRACKING_SESSION_STORAGE_KEY } from '@lendi-jam/jam-link';
import { Environment, Session, SessionProvider, Type } from '@lendi/lala-react';
import { getBrandFromHostname } from '@lendi/lala-utils';
import { CommPanelProvider } from '@lendi/navbar';
import { Theme } from '@lendi/ui/Theme';

import LaunchDarklyProvider from '#components/LaunchDarklyProvider';

import { GoogleOtpSessionProvider } from '../../utils/googleOtpSessionProvider';

const mapGatsbyEnvironmentToEnv = () => {
  switch (process.env.GATSBY_ENVIRONMENT) {
    case 'development':
      return Environment.Development;
    case 'staging':
      return Environment.Staging;
    case 'preproduction':
      return Environment.Preproduction;
    case 'production':
      return Environment.Production;
    default:
      return Environment.Development;
  }
};

const session = new Session({
  brand: getBrandFromHostname(),
  type: Type.Customer,
  environment: mapGatsbyEnvironmentToEnv(), // TODO: Remove GatsbyEnvironment and use lala-utils getEnv()
});

export const AppShell: React.FC = ({ children }) => {
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const marketingTrackingParams: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      if (MARKETING_TRACKING_PARAM_LIST.includes(key) && !!value) {
        marketingTrackingParams[key] = value;
      }
    });
    if (Object.keys(marketingTrackingParams).length > 0) {
      sessionStorage.setItem(MARKETING_TRACKING_SESSION_STORAGE_KEY, JSON.stringify(marketingTrackingParams));
    }
  }, []);

  return (
    <SessionProvider session={session}>
      <LaunchDarklyProvider>
        <CommPanelProvider>
          <Theme name={'lendi'} disableFallbackFonts>
            <GoogleOtpSessionProvider>{children}</GoogleOtpSessionProvider>
          </Theme>
        </CommPanelProvider>
      </LaunchDarklyProvider>
    </SessionProvider>
  );
};
