import * as React from 'react';

import { datadogRum } from '@datadog/browser-rum';
import type { WrapPageElementBrowserArgs } from 'gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';

import { AppShell } from './src/layout/AppShell';

// Adds a shared React Context for Prismic preview sessions.
export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  datadogRum.init({
    applicationId: '3aed14b6-76ea-471b-8b95-b56716e37f89',
    clientToken: 'pubfb8162621270b6adf0dd15d825860dff',
    site: 'datadoghq.com',
    service: 'cms',
    env: process.env.GATSBY_ENVIRONMENT,
    version: process.env.GATSBY_COMMIT,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
  });

  return (
    <PrismicPreviewProvider>
      <AppShell>{element}</AppShell>
    </PrismicPreviewProvider>
  );
};
