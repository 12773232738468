import React from 'react';

import { tracking } from '@lendi-jam/tracking';
import { EventCategory } from '@lendi/analytics-web';
import { useGoogleOTIntegration } from '@lendi/lala-react';
import { LalaSentry } from '@lendi/lala-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const GoogleOtpSessionProvider: React.FC = ({ children }) => {
  const { lendiGoogleSsoOneTapIntegration } = useFlags();
  const env = process.env.GATSBY_ENVIRONMENT || 'development';
  LalaSentry.logInfo('useFlags in GoogleOtpSessionProvider', { lendiGoogleSsoOneTapIntegration, env });
  useGoogleOTIntegration({
    enabled: lendiGoogleSsoOneTapIntegration,
    googleOAuthClientId: process.env.GATSBY_GOOGLE_OAUTH_CLIENT_ID!,
    env,
    events: {
      onRendered: () => {
        tracking.event({
          event_name: 'Google OT Rendered',
          category: EventCategory.AUTH,
          path: window.location.pathname,
        });
        return {};
      },
      onSelected: () => {
        tracking.event({
          event_name: 'Google OT Selected',
          category: EventCategory.AUTH,
          path: window.location.pathname,
        });
        return {};
      },
    },
  });

  return <>{children}</>;
};
